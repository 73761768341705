const appLinks = {
    ios: 'https://apps.apple.com/au/app/mysa-gov/id1124301168',
    android: 'https://play.google.com/store/apps/details?id=au.gov.sa.my&hl=en_AU&gl=US'
}

function Redirect() {
    var userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
        window.location.href = appLinks.android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href = appLinks.ios;
    }

    return null;
}

export default Redirect;
import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import CheckInForm from './components/CheckInForm';
import Redirect from './components/AppStoreRedirect';

function App() {
  return (
    <Router>
      <Route exact path="/">
        <CheckInForm />
      </Route>
      <Route exact path="/covidCertificate">
        <Redirect></Redirect>
      </Route>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { checkIn, confirmMobileVerification, getLastCheckIn, getPhoneNumberToken } from '../utls/api';
import useQuery from '../utls/useQuery';
import ConfirmContactDetailsStep from './ConfirmContactDetailsStep';
import ConfirmedCheckIn from './ConfirmedCheckIn';
import ConfirmNumberStep, { confirmNumberSchema } from './ConfirmNumberStep';
import ContactDetailsStep from './ContactDetailsStep';
import { Wizard } from './Wizard';
import { ReactComponent as Logo } from '../assets/COVIDSafeLogo.svg';
import CheckInList from './CheckInList';

export interface CheckInQueryParams {
    businessName: string | null;
    businessAddress: string | null;
    planId: string | null;
}

export const useCheckInQueryParams = (): CheckInQueryParams => {
    const query = useQuery();
    return {
        businessName: query.get("businessName"),
        businessAddress: query.get("businessAddress"),
        planId: query.get("planId")
    }
}

export interface CheckInFormFields {
    firstName?: string,
    lastName?: string,
    mobileNumber?: string,
    otaCode?: string
}

export const FormTitle = styled.h4`
    border-bottom: 1px solid grey;
`;

const Footer = styled.div`
    flex-shrink: 0;
    padding-left: 15px;
    margin-top: 12px;

    p {
        color: grey;
        font-size: 0.85rem;
    }
`;

const FormContainer = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1 0 auto;
    }
`;

const initialFormValues: CheckInFormFields = {
    firstName: '',
    lastName: '',
    mobileNumber: '+61',
    otaCode: ''
}

const getInitialFormValues = (): CheckInFormFields => {
    const token = getPhoneNumberToken(null);
    const lastCheckIn = getLastCheckIn();

    if (token && lastCheckIn) {
        return {
            firstName: lastCheckIn.firstName,
            lastName: lastCheckIn.lastName,
            mobileNumber: '+' + token.mobileNumber,
            otaCode: ''
        }
    }
    return initialFormValues;
}

const CheckInForm = () => {
    const params = useCheckInQueryParams();
    const [jwtToken, setJwtToken] = useState('');
    const [initialState, setInitialState] = useState(getInitialFormValues());

    const invalidFormUrl = (!params.businessName || (!params.businessAddress && !params.planId));

    return <FormContainer>
        <Container className="content">
            <Container fluid className="mt-3 mb-3">
                <Row>
                    <Col xs={3} sm={2}><Logo style={{maxWidth: 70}}/></Col>
                    <Col className="align-self-center"><h4 className="text-center mb-0">COVID-Safe Check In</h4></Col>
                </Row>
            </Container>
             <Container className="text-center pt-3">
                <div style={{padding: "20px",marginBottom: "15px"}}><h5>There are no longer any requirements to use COVID Safe Check-In within any organisation in South Australia.</h5></div>
            </Container>

            {/* {invalidFormUrl && <CheckInList />}

            {!invalidFormUrl && <Wizard initialValues={initialState}>
                {ContactDetailsStep}
                <ConfirmNumberStep skipIfPhoneVerified setJwtToken={setJwtToken} validationSchema={confirmNumberSchema} netSubmission onSubmit={(values: CheckInFormFields) => {
                    const storedToken = getPhoneNumberToken(values.mobileNumber!);
                    if (storedToken) {
                        return Promise.resolve(setJwtToken(storedToken.token))
                    }

                    return confirmMobileVerification(values.mobileNumber!, values.otaCode!)
                    .then(jwt => Promise.resolve(setJwtToken(jwt)));}} />
                <ConfirmContactDetailsStep onSubmit={(values: CheckInFormFields) => checkIn(values, params, jwtToken)} netSubmission submitText="Check In"/>
                <ConfirmedCheckIn />
            </Wizard>} */}


        </Container>

        <Footer>
            <p>Version: {process.env.REACT_APP_SPA_VERSION}</p>
        </Footer>
    </FormContainer>
}

export default CheckInForm;
import axios from 'axios';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { CheckInFormFields, CheckInQueryParams } from '../components/CheckInForm';

export const lastCheckInStorageField = "lastCheckIn";

export interface LastCheckIn {
    businessName: string,
    checkInTime: Date,
    firstName: string,
    lastName: string
}

const tokenStorageField = 'phoneNumberToken';

export interface PhoneNumberToken {
    mobileNumber: string,
    token: string
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CHECKIN_API_URL!,
})

export const beginMobileVerification = (mobileNumber: string): Promise<any> => {
    // Replace spaces, + and ()
    mobileNumber = mobileNumber.replace(/\s|\(|\)|\+/g, "");

    if (getPhoneNumberToken(mobileNumber)) return Promise.resolve();

    return axiosInstance.get('/begin-mobile-verification?mobileNumber=' + mobileNumber);
}

export const confirmMobileVerification = (mobileNumber: string, verificationCode: string): Promise<string> => {
    mobileNumber = mobileNumber.replace(/\s|\(|\)|\+/g, "");

    return axiosInstance.get('/complete-mobile-verification?mobileNumber=' + mobileNumber + '&verificationCode=' + verificationCode)
        .then(response => response.data.token)
        .then(token => {
            storePhoneNumberToken({
                token,
                mobileNumber
            });
            return token;
        });
}

const storeLastCheckIn = (checkIn: LastCheckIn) => {
    localStorage.setItem(lastCheckInStorageField, JSON.stringify(checkIn));
}

export const getLastCheckIn = (): LastCheckIn | null => {
    const stringCheckIn = localStorage.getItem(lastCheckInStorageField);
    if (!stringCheckIn) return null;

    const lastCheckIn: LastCheckIn = JSON.parse(stringCheckIn);
    return {
        ...lastCheckIn,
        checkInTime: new Date(lastCheckIn.checkInTime)
    }
}

const storePhoneNumberToken = (token: PhoneNumberToken) => {
    localStorage.setItem(tokenStorageField, JSON.stringify(token));
}

const clearPhoneNumberToken = () => {
    localStorage.removeItem(tokenStorageField);
}

export const getPhoneNumberToken = (number: string | null): PhoneNumberToken | null => {
    if (number) number = number.replace(/\s|\(|\)|\+/g, "");

    // Grab stored token from localStorage
    const storedToken = localStorage.getItem(tokenStorageField);
    if (!storedToken) return null;

    // Parse token and check expiry, clearing token if expired
    const token: PhoneNumberToken = JSON.parse(storedToken);
    const jwt = jwt_decode<JwtPayload>(token.token);
    if (!jwt.exp || jwt.exp * 1000 < Date.now() ) {
        clearPhoneNumberToken();
        return null;
    }

    // If a number was provided, check that the stored token is for that number
    if (number && number !== token.mobileNumber) {
        clearPhoneNumberToken();
        return null;
    }

    return token;
}

export const checkIn = (formParams: CheckInFormFields, checkinParams: CheckInQueryParams, token: String): Promise<any> => {
    formParams.mobileNumber = formParams.mobileNumber?.replace(/\s|\(|\)|\+/g, "");

    return axiosInstance.post('checkin', {
        firstName: formParams.firstName,
        lastName: formParams.lastName,
        planId: checkinParams.planId,
        businessName: checkinParams.businessName,
        businessAddress: checkinParams.businessAddress
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then((response) => {
        storeLastCheckIn({
            businessName: checkinParams.businessName!,
            checkInTime: new Date(),
            firstName: formParams.firstName!,
            lastName: formParams.lastName!
        })
    });
}